import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "building.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="relative overflow-hidden bg-teal-800">
      <div className="max-w-screen-xl mx-auto ">
        <div className="relative z-10 py-8 sm:py-16 md:py-20 lg:max-w-2xl lg:w-full lg:py-28 xl:py-32">
          <div className="pt-6 px-4 sm:px-6 lg:px-8" />
          <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-4xl tracking-tight leading-10 font-medium text-white sm:text-5xl sm:leading-none md:text-6xl">
                Bilgri & Manske, SC.
              </h2>

              <ul className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                <li>
                  Tel:{" "}
                  <a
                    className="underline hover:underline"
                    href="tel:+1-920-887-3131"
                  >
                    (920)-887-3131
                  </a>
                </li>
                <li>Fax: 920-887-3195</li>
                <li>
                  <a
                    href="https://goo.gl/maps/hPUh8gaUFpkGbL1a9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:underline"
                  >
                    PO Box 477 Beaver Dam, WI 53916
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          alt="Bilgri & Manske Building"
          fluid={data.file.childImageSharp.fluid}
          style={{ mixBlendMode: "soft-light" }}
          fadeIn={false}
          loading={"eager"}
        />
      </div>
    </div>
  );
};

export default Hero;
