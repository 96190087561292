import React from "react";
import { handleChange, handleSubmit } from "../../utils/netlify";
import WufooForm from "../WufooForm";

const Contact = () => {
  const [state, setState] = React.useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
    "bot-field": "",
  });
  return (
    <section className="relative bg-white mt-16" id="contact">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-teal-800" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-teal-800 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="text-center md:text-left max-w-lg mx-auto">
            <h2 className="text-4xl leading-8 font-bold tracking-tight text-white sm:leading-9">
              Get in touch
            </h2>
            <p className="mt-3 text-lg leading-6 text-white">
              For questions and inquiries, please contact us via phone or
              contact form submission.
            </p>
            <dl className="mt-8 text-base leading-6 text-white">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <a
                    href="https://goo.gl/maps/hPUh8gaUFpkGbL1a9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline hover:underline"
                  >
                    PO Box 477
                    <br /> Beaver Dam, WI 53916
                  </a>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex justify-center md:justify-start">
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <span className="ml-3">
                    <a
                      className="underline hover:underline"
                      href="tel:+1-920-887-3131"
                    >
                      920-887-3131
                    </a>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-4 lg:py-8 px-4 sm:px-6 lg:px-8 xl:pl-12 lg:col-span-3">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <WufooForm formHash={"p1q1o0a10eoog2v"} userName={"interquest"} />
            {/*<form*/}
            {/*  onSubmit={(e) => handleSubmit(e, state)}*/}
            {/*  name={"Contact"}*/}
            {/*  className="grid grid-cols-1 row-gap-6"*/}
            {/*  method="post"*/}
            {/*  action="/thanks"*/}
            {/*  data-netlify="true"*/}
            {/*  data-netlify-honeypot="bot-field"*/}
            {/*>*/}
            {/*  <div>*/}
            {/*    <p className="hidden">*/}
            {/*      <label>*/}
            {/*        Don’t fill this out if you are human:{" "}*/}
            {/*        <input*/}
            {/*          name="bot-field"*/}
            {/*          value={state["bot-field"]}*/}
            {/*          onChange={(e) => handleChange(e, setState)}*/}
            {/*        />*/}
            {/*      </label>*/}
            {/*      /!*<input type="hidden" name="form-name" value="Contact" />*!/*/}
            {/*    </p>*/}
            {/*    <label htmlFor="fullName" className="sr-only">*/}
            {/*      Full name*/}
            {/*    </label>*/}
            {/*    <div className="relative rounded-md shadow-sm">*/}
            {/*      <input*/}
            {/*        id="fullName"*/}
            {/*        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"*/}
            {/*        placeholder="Full name"*/}
            {/*        name="fullName"*/}
            {/*        value={state["fullName"]}*/}
            {/*        onChange={(e) => handleChange(e, setState)}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <label htmlFor="email" className="sr-only">*/}
            {/*      Email*/}
            {/*    </label>*/}
            {/*    <div className="relative rounded-md shadow-sm">*/}
            {/*      <input*/}
            {/*        id="email"*/}
            {/*        type="email"*/}
            {/*        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"*/}
            {/*        placeholder="Email"*/}
            {/*        name="email"*/}
            {/*        value={state["email"]}*/}
            {/*        onChange={(e) => handleChange(e, setState)}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <label htmlFor="phone" className="sr-only">*/}
            {/*      Phone*/}
            {/*    </label>*/}
            {/*    <div className="relative rounded-md shadow-sm">*/}
            {/*      <input*/}
            {/*        id="phone"*/}
            {/*        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"*/}
            {/*        placeholder="Phone"*/}
            {/*        name="phone"*/}
            {/*        value={state["phone"]}*/}
            {/*        onChange={(e) => handleChange(e, setState)}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <label htmlFor="message" className="sr-only">*/}
            {/*      Message*/}
            {/*    </label>*/}
            {/*    <div className="relative rounded-md shadow-sm">*/}
            {/*      <textarea*/}
            {/*        id="message"*/}
            {/*        rows={4}*/}
            {/*        className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"*/}
            {/*        placeholder="Message"*/}
            {/*        name="message"*/}
            {/*        value={state["message"]}*/}
            {/*        onChange={(e) => handleChange(e, setState)}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="">*/}
            {/*    <span className="inline-flex w-full rounded-md shadow-sm">*/}
            {/*      <button*/}
            {/*        type="submit"*/}
            {/*        className="inline-flex w-full justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary-alt focus:shadow-outline-cool-gray active:bg-primary-alt transition duration-150 ease-in-out"*/}
            {/*      >*/}
            {/*        Submit*/}
            {/*      </button>*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</form>*/}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
