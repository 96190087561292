import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import HeroSection from "../components/sections/Hero";
import LinksSection from "../components/sections/Links";
import ContactSection from "../components/sections/Contact";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        keywords={[
          `bilgri, accountant, tax, tax preparer, manske, bilgri & manske, bilgri and manske, beaver dam`,
        ]}
        title="Home"
      />
      <HeroSection />
      <LinksSection />
      <ContactSection />
    </Layout>
  );
};

export default IndexPage;
