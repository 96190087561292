import React from "react";

const LinksSection = () => {
  const taxReturnLinks = [
    {
      url: "https://file.1040.com/1040/signin",
      title: "File your return online",
    },
    {
      title: "Where's my federal refund?",
      url: "https://www.irs.gov/refunds",
    },
    {
      title: "Where's my Wisconsin refund?",
      url: "https://www.revenue.wi.gov/Pages/Apps/TaxReturnStatus.aspx",
    },
  ];

  const taxFormLinks = [
    {
      url: "https://www.irs.gov/app/picklist/list/formsInstructions.html",
      title: "Federal Tax Forms",
    },
    {
      url: "https://www.revenue.wi.gov/Pages/HTML/formpub.aspx",
      title: "State Tax Forms",
    },
  ];

  const resourceLinks = [
    {
      url: "https://iop.intuit.com/login/login.jsf",
      title: "Payroll",
    },
  ];

  const fileTransferLinks = [
    {
      url: "https://bilgri.sharefile.com/",
      title: "Secure file upload / transfer",
    },
  ];

  const linksByCategory = [
    {
      heading: "Tax Forms",
      links: taxFormLinks,
    },
    {
      heading: "Tax Returns",
      links: taxReturnLinks,
    },
    {
      heading: "Resources",
      links: resourceLinks,
    },
    {
      heading: "File Transfer",
      links: fileTransferLinks,
    },
  ];

  return (
    <section className="container mx-auto mt-16 py-8 px-4">
      <h2 className="text-5xl border-b border-teal-600 inline-block mx-auto">
        Helpful Links
      </h2>
      <div className="flex flex-wrap mt-4">
        {linksByCategory.map(({ heading, links }, i) => (
          // column
          <div key={i} className="w-full lg:w-1/2 p-6">
            <CardWithList
              heading={heading}
              id={heading.replace(/\s+/g, "").toLowerCase()} // removes spaces from heading for our anchor links. make lowercase to match Nav links
              links={links}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

const CardWithList = ({ heading, id, links }) => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md" id={id}>
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 bg-cool-gray-200">
        <h3 className="text-2xl leading-6 font-bold text-cool-gray-900 uppercase">
          {heading}
        </h3>
      </div>
      <ul>
        {links.map((link, i) => (
          <li key={i}>
            <a
              href={link.url}
              className="block bg-white hover:bg-gray-100 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4">
                    <div>
                      <div className="text-md leading-5 text-teal-600">
                        {link.title}
                      </div>
                      <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                        <span className="truncate font-light">{link.url}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex text-teal-500">
                  {/* <span className="font-light">Click to Visit</span> */}
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinksSection;
